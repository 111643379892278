@font-face {
  font-family: 'ElectronPulseItalic';
  src: url('../fonts/Electron-Pulse-Italic.otf') format('opentype'),
       url('../fonts/Electron-Pulse-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

header {
    width: 100%;
    height: 96px !important; /* Set a fixed height for the header */
    background: var(--Main-Color, #92C83E);
    display: flex;
    padding-left: 30px; /* Add padding from the left side */
    z-index: 1000;
  }
  
  
  .header-container {
    display: flex;
    align-items: center; /* Vertically centers the content */
    justify-content: flex-start; /* Aligns content to the left */
  }
  
  .header-logo button{
    font-family: 'ElectronPulseItalic';
    font-size: 64px; /* Adjust font size as needed */
    color: white;
  }