#wrapper {
    position: relative;
    height: 100%;
    margin-top: 3dvh;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 35px;
}

.loading small i {
    color: #9f9f9f;
    position: relative;
    top: 65px;
}

.loading small {
    text-align: center;
}
.profile-main-loader {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 45px;
    height: 45px;
    top: 10px;
    z-index: 9000;
}

.profile-main-loader .loader {
    width: 100%;
    height: 100%;
}

.profile-main-loader .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.circular-loader {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
}

.loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes color {
    0% {
        stroke: #92C83E;
    }
    40% {
        stroke: #92C83E;
    }
    66% {
        stroke: #92C83E;
    }
    80%, 90% {
        stroke: #92C83E;
    }
}
